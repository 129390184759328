import { createContext, useContext, useEffect, useState } from "react";
import client from "firebase/client";
import nookies from "nookies";

const AuthContext = createContext<{ user: client.User | null }>({
  user: null,
});

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<client.User | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      (window as any).nookies = nookies;
    }
    return client.auth().onIdTokenChanged(async (user) => {
      if (!user) {
        setUser(null);
        return;
      }
      setUser(user);
    });
  }, []);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = client.auth().currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
}
