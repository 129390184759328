import "styles/globals.css";
import type { AppProps } from "next/app";
import { AuthProvider } from "contexts/auth";
import { init } from "utils/sentry";
// to fix queryselector bug see https://github.com/vercel/next.js/issues/15883
if (typeof document === "undefined") {
  // @ts-ignore
  global.document = {
    querySelector: function () {},
  };
}

init();

function App({ Component, pageProps }: AppProps) {
  return (
    <div>
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>
    </div>
  );
}

export default App;
